var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropdown,
          expression: "closeDropdown",
        },
      ],
      staticClass: "dropdown",
    },
    [
      _vm._t(
        "toggleButton",
        function () {
          return [
            _c(
              "a",
              {
                staticClass: "dropdown-toggle nav-link",
                class: { "no-caret": _vm.hideArrow },
                attrs: { "data-toggle": "dropdown" },
              },
              [
                _c("i", { class: _vm.icon }),
                _vm._v(" "),
                _c("span", { staticClass: "no-icon" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]
            ),
          ]
        },
        { toggleDropdown: _vm.toggleDropdown }
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "dropdown-menu sync-width-popup pb-1",
          class: [
            { "dropdown-menu-right": _vm.position === "right" },
            { show: _vm.isOpen },
            _vm.menuClasses,
          ],
        },
        [_vm._t("dropdown", null, { toggleDropdown: _vm.toggleDropdown })],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }