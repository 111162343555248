var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !this.$route.path.includes("/contact"),
          expression: "!this.$route.path.includes('/contact')",
        },
      ],
    },
    [
      _c("div", {
        directives: [
          {
            name: "scroll-lock",
            rawName: "v-scroll-lock",
            value: !_vm.$device.isIos && _vm.toggled,
            expression: "!$device.isIos && toggled",
          },
        ],
        class: [{ overlay: true }, { custom: true }, { active: _vm.toggled }],
      }),
      _vm._v(" "),
      _c(
        "nav",
        {
          staticClass: "navbar",
          class: [
            { "navbar-expand-lg": _vm.expand },
            { [`navbar-${_vm.effect}`]: _vm.effect },
            { "navbar-transparent": _vm.transparent },
            { [`bg-${_vm.type}`]: _vm.type },
            { rounded: _vm.round },
            {
              "navbar-homepage":
                _vm.$auth.loggedIn &&
                _vm.$auth.user.role === "CANDIDATE" &&
                (_vm.$route.name.includes("index") ||
                  _vm.$route.name.includes("recruitment")),
            },
            { "bg-img-not-login": !_vm.$auth.loggedIn },
          ],
          attrs: { id: "top-nav-header" },
        },
        [
          _c(
            "div",
            { staticClass: "container nav-height px-0" },
            [
              _vm._t("container-pre"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "children-on-full w-100" },
                [
                  _vm._t("brand", function () {
                    return [
                      _c(
                        "a",
                        {
                          staticClass: "navbar-brand",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onTitleClick.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.title))]
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("client-only", [
                _c(
                  "ul",
                  {
                    staticClass:
                      "toggle-wrapper container mb-0 px-3 align-items-center navigation-bar",
                    class: { show: _vm.toggled },
                  },
                  [
                    _vm._t("brand", function () {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "navbar-brand",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onTitleClick.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.title))]
                        ),
                      ]
                    }),
                    _vm._v(" "),
                    !_vm.isDetectMobile
                      ? _c("li", { staticClass: "d-flex flex-9" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$auth.user && _vm.isDetectMobile
                      ? _c(
                          "li",
                          {
                            staticClass:
                              "nav-item notification-icon hide-view-small-mobile mx-1",
                          },
                          [_c("notification-component")],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$auth.loggedIn && _vm.isDetectMobile
                      ? _c(
                          "li",
                          { staticClass: "nav-item mr-3" },
                          [_c("switch-language-application")],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "nav-item mr-0" },
                      [
                        _c("navbar-toggle-button", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.$route.name.includes("login") &&
                                !_vm.$route.name.includes("sign-up") &&
                                !_vm.$route.name.includes("resend-mail") &&
                                !_vm.$route.name.includes("forgot-password"),
                              expression:
                                "!$route.name.includes('login') && !$route.name.includes('sign-up')\n               && !$route.name.includes('resend-mail') && !$route.name.includes('forgot-password')",
                            },
                          ],
                          staticClass: "mr-0",
                          attrs: {
                            toggled: _vm.toggled,
                            target: _vm.contentId,
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.toggled = !_vm.toggled
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _vm._t("container-after"),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.closeMenu,
                      expression: "closeMenu",
                    },
                  ],
                  staticClass: "collapse d-block navbar-collapse",
                  class: { show: _vm.toggled },
                  attrs: { id: _vm.contentId },
                },
                [
                  _c(
                    "div",
                    { staticClass: "navbar-collapse-header" },
                    [
                      _vm._t("content-header", null, {
                        closeMenu: _vm.closeMenu,
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "children-on-full" },
                    [
                      _vm._t("children-on-full", null, {
                        closeMenu: _vm.closeMenu,
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "children-on-dropdown" },
                    [
                      _vm._t("children-on-dropdown", null, {
                        closeMenu: _vm.closeMenu,
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }