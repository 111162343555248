<template>
  <div v-click-outside="closeDropdown" class="dropdown">
    <slot name="toggleButton" :toggleDropdown="toggleDropdown">
      <a class="dropdown-toggle nav-link" :class="{'no-caret': hideArrow}" data-toggle="dropdown">
        <i :class="icon" />
        <span class="no-icon">{{ title }}</span>
      </a>
    </slot>
    <ul
      class="dropdown-menu sync-width-popup pb-1"
      :class="[{'dropdown-menu-right': position === 'right'}, {show: isOpen}, menuClasses]"
    >
      <slot  name="dropdown" :toggleDropdown="toggleDropdown" />
    </ul>
  </div>
</template>

// @click="toggleDropdown"
<!--<component class="dropdown"-->
           <!--:is="tag"-->
           <!--:class="[{show: isOpen}, {'dropdown': direction === 'down'}, {'dropup': direction ==='up'}]"-->
           <!--aria-haspopup="true"-->
           <!--:aria-expanded="isOpen"-->
           <!--@click="toggleDropdown"-->
           <!--v-click-outside="closeDropdown">-->
<script>
import SetWidthDOM from '~/mixins/SetWidthDOM.vue'
export default {
  name: "BaseDropdown",
  mixins: [SetWidthDOM],
  props: {
    preventToggle: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: "down"
    },
    title: {
      type: String,
      description: "Dropdown title"
    },
    icon: {
      type: String,
      description: "Icon for dropdown title"
    },
    position: {
      type: String,
      description: "Position of dropdown menu (e.g right|left)"
    },
    menuClasses: {
      type: [String, Object],
      description: "Dropdown menu classes"
    },
    hideArrow: {
      type: Boolean,
      description: "Whether dropdown arrow should be hidden"
    },
    tag: {
      type: String,
      default: "li",
      description: "Dropdown html tag (e.g div, li etc)"
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  async asyncData() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleDropdown() {
      // if(this.preventToggle && this.isOpen) return;
      this.isOpen = !this.isOpen;
      this.$emit("change", this.isOpen);
    },
    closeDropdown() {
      this.isOpen = false;
      this.$emit("change", this.isOpen);
    }
  }
};
</script>
<style>
.dropdown {
  list-style-type: none;
}

.dropdown .dropdown-toggle {
}
</style>
