<template>
  <div v-show="!this.$route.path.includes('/contact')">
    <div :class="[{'overlay': true},{'custom': true},{'active': toggled}]" v-scroll-lock="!$device.isIos && toggled"></div>
    <nav
      class="navbar"
      id="top-nav-header"
      :class="[
        {'navbar-expand-lg': expand},
        {[`navbar-${effect}`]: effect},
        {'navbar-transparent': transparent},
        {[`bg-${type}`]: type},
        {'rounded': round},
        {'navbar-homepage': $auth.loggedIn && $auth.user.role === 'CANDIDATE' 
          && ($route.name.includes('index') || $route.name.includes('recruitment'))},
        {'bg-img-not-login': !$auth.loggedIn}
      ]"
    >
      <div class="container nav-height px-0">
        <slot name="container-pre" />
        <div class="children-on-full w-100">
          <slot name="brand">
            <a class="navbar-brand" href="#" @click.prevent="onTitleClick">{{ title }}</a>
          </slot>
        </div>
        <client-only>
          <ul class="toggle-wrapper container mb-0 px-3 align-items-center navigation-bar" 
            :class="{show: toggled}">
            <slot name="brand">
              <a class="navbar-brand" href="#" @click.prevent="onTitleClick">{{ title }}</a>
            </slot>
            <li class='d-flex flex-9' v-if="!isDetectMobile"></li>
            <li class="nav-item notification-icon hide-view-small-mobile mx-1" v-if="$auth.user && isDetectMobile">
              <notification-component />
            </li>
            <li class="nav-item mr-3" v-if="!$auth.loggedIn && isDetectMobile">
              <switch-language-application />
            </li>
            <li class="nav-item mr-0">
              <navbar-toggle-button
                :toggled="toggled"
                :target="contentId"
                @click.native.stop="toggled = !toggled"
                class="mr-0"
                v-show="!$route.name.includes('login') && !$route.name.includes('sign-up')
                 && !$route.name.includes('resend-mail') && !$route.name.includes('forgot-password')"
              />
            </li>
            <!-- <a @click="onLogoClick" style="order: -1">
              <img src="~/assets/icons/icon-logo-white.svg" alt="">
            </a> -->
          </ul>
        </client-only>
        <slot name="container-after" />
        <!-- <search-nav-bar 
          v-show="this.$auth.user && this.$auth.user.role == 'CANDIDATE'"
          :class="[{'search-nav-bar_homepage': $auth.loggedIn && $auth.user.role === 'CANDIDATE' && $route.name.includes('index')}]"/> -->
        <div
          :id="contentId"
          v-click-outside="closeMenu"
          class="collapse d-block navbar-collapse"
          :class="{show: toggled}"
        >
          <div class="navbar-collapse-header">
            <slot name="content-header" :close-menu="closeMenu" />
          </div>
          <div class="children-on-full">
            <slot name="children-on-full" :close-menu="closeMenu" />
          </div>
          <div class="children-on-dropdown">
            <slot name="children-on-dropdown" :close-menu="closeMenu" />
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import CommonMixins from "~/mixins/CommonMixins.vue";
export default {
  name: "BaseNav",
  mixins: [CommonMixins],
  components: {
    FadeTransition,
    NavbarToggleButton: () => import('./NavbarToggleButton'),
    NotificationComponent: () => import('~/components/NotificationComponent.vue'),
    SwitchLanguageApplication: () => import('~/components/SwitchLanguageApplication.vue'),
  },
  props: {
    type: {
      type: String,
      default: "primary",
      description: "Navbar type (e.g default, primary etc)"
    },
    title: {
      type: String,
      default: "",
      description: "Title of navbar"
    },
    contentId: {
      type: [String, Number],
      default: Math.random().toString(),
      description:
        "Explicit id for the menu. By default it's a generated random number"
    },
    effect: {
      type: String,
      default: "dark",
      description: "Effect of the navbar (light|dark)"
    },
    round: {
      type: Boolean,
      default: false,
      description: "Whether nav has rounded corners"
    },
    transparent: {
      type: Boolean,
      default: false,
      description: "Whether navbar is transparent"
    },
    expand: {
      type: Boolean,
      default: false,
      description: "Whether navbar should contain `navbar-expand-lg` class"
    }
  },
  data() {
    return {
      toggled: false
    };
  },
  methods: {
    onTitleClick(evt) {
      this.$emit("title-click", evt);
    },
    closeMenu() {
      this.toggled = false;
    },
    mounted(){
    },
    onChangeSearch(evt) {
      this.$emit('on-change-search',evt);
    },
    onLogoClick() {
      this.$emit('logo-click');
    }
  }
};
</script>
<style lang="scss" scoped>
.navbar-collapse{
  flex-grow: 0;
}
.nav-height {
  height: 70px;
  position: relative;
  left: -8px;
  @media (max-width: 768px) {
    left: 0;
  }
}
.toggle-wrapper {
  display: none;
  justify-content: flex-end;
  // padding-right: 25px;
  list-style: none;
}
.children-on-full {
  // display: flex;
  flex: 1;
}
.children-on-dropdown {
  display: none;
}
@media (max-width: 991.98px) {
  .toggle-wrapper {
    display: flex;
  }
  .children-on-full {
    display: none;
  }
  .children-on-dropdown {
    display: block;
    background: $second-background;
    padding-bottom: 2rem;
  }
  .navbar-collapse {
    position: fixed;
    // width: calc(100% - 3rem);
    width: 100%;
    bottom: 0;
    margin: 0;
    opacity: 1;
    z-index: 9999;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: transform .3s ease;
    &.show {
      border-radius: 0 0.25rem 0 0 !important;
      background: #FEFEFE;
    }
  }
  .navbar-collapse .navbar-collapse-header {
    padding: 1.5rem 1rem;
    // padding-bottom: 0.5rem;
    border: none;
    background: $second-background;
  }
 .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: rgba(0, 0, 0, 0.6);
      background: #E5E5E5;
      display: none;
      z-index: 999;
      backface-visibility: hidden;
      transition: translate3d(0, 0, 0);
  }
  .overlay.custom {
      display: block;
      -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      transform: translateX(-100%);
      -webkit-transition: -webkit-transform .2s;
      -o-transition: transform .2s;
      transition: transform .2s, -webkit-transform .2s;
  }
  .overlay.custom.active {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
  }
  .nav-item.area-searching {
    align-items: center;
  display: flex;
  }
  .area-searching .input-group-text {
    // border-radius: 1.6rem;
    // margin-top: 2px;
    cursor: pointer;
  }
  @keyframes show-navbar-collapse {
    0% {
      -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
  }
}
.navbar {
  // padding: 0.75rem 1rem;
  &.navbar-homepage {
    background-color: rgba(0, 0, 0, 0.2) !important;
    // backdrop-filter: blur(6px);
  }
  // &.bg-img-not-login {
  //   width: 100%;
  //   // height: 68px;
  //   background: #2e3862 url('/img/landing-page/header_bg_pc.png') no-repeat;
  //   background-attachment: fixed;
  //   background-size: cover;
  // }
}
.notification-icon {
  align-items: center;
  display: flex;
}
.show-responsive {
  @media screen and (min-width: 992px) {
    display: none;
  }
}
.search-nav-bar_homepage {
  & :deep( .input-group-search  ) {
    background: rgba(254, 254, 255, 0.2);
  }
}
</style>
